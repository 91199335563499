<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <div v-if="isAdmin" class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="entity.site_type_id"
                                        label="Тип"
                                        :items="siteTypes"
                                        item-text="title"
                                        item-value="id"
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="entity.start_page"
                                        label="Стартовая страница"
                                        :items="startPages"
                                        item-text="title"
                                        item-value="code"
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    Настройки доступа
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pt-0">
                                    <v-switch
                                        v-model="permitions.delivery"
                                        label="Сайт доставки"
                                        class="mt-0"
                                        hide-details
                                    />
                                </v-col>
                                <v-col class="pt-0">
                                    <v-switch
                                        v-model="permitions.full_menu"
                                        label="Электронное меню"
                                        class="mt-0"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                    @input="updateTitle"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.host"
                                    outlined
                                    label="Привязанный домен"
                                    :prefix="'https://'"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.code"
                                    outlined
                                    label="Адрес сайта доставки"
                                    :prefix="siteLinkPreset"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-img width="300px" v-if="entity.picture" :src="imageSrc(entity.picture)" class="mb-4" />
                                <v-file-input
                                    label="Картинка"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setPicture"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="entity.description"
                                    outlined
                                    label="Описание"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="entity.description_seo"
                                    outlined
                                    label="Описание для SEO"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.site"
                                    outlined
                                    label="Сайт"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="!permitions.full_menu">
                            <v-col>
                                <div v-if="entity.menu_file" class="d-flex">
                                    <v-icon color="primary" class="mr-1">mdi-file</v-icon>
                                    <a :href="imageSrc(entity.menu_file)" target="_blank">Файл с меню</a>
                                    <v-icon color="error" class="ml-6" @click="removeMenuFile">mdi-trash-can</v-icon>
                                </div>
                                <v-file-input
                                    v-else
                                    label="Файл с меню"
                                    outlined
                                    prepend-icon="mdi-file"
                                    hide-details
                                    @change="setMenuFile"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="!permitions.delivery">
                            <v-col>
                                <v-text-field
                                    v-model="entity.delivery_url"
                                    outlined
                                    label="Ссылка на сайт доставки"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="!permitions.full_menu">
                            <v-col>
                                <div v-if="entity.legal_information" class="d-flex">
                                    <v-icon color="primary" class="mr-1">mdi-file</v-icon>
                                    <a :href="imageSrc(entity.legal_information)" target="_blank">Правовая информация</a>
                                    <v-icon color="error" class="ml-6" @click="removeLegalInformation">mdi-trash-can</v-icon>
                                </div>
                                <v-file-input
                                    v-else
                                    label="Правовая информация"
                                    outlined
                                    prepend-icon="mdi-file"
                                    hide-details
                                    @change="setLegalInformation"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="entity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>


        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить сервис? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    import { imageSrc, translit } from '../../helpers';

    export default {
        name: 'SiteEdit',

        components: {
            SiteTabLinks,
            yandexMap, 
            ymapMarker
        },

        data() {
            return {
                imageSrc,
                entity: {
                    site_type_id: null,
                    code: '',
                    host: '',
                    title: '',
                    picture: '',
                    description: '',
                    enabled: true,
                    position: 0
                },
                startPages: [
                    { code: 'site', title: 'Сайт' },
                    { code: 'delivery', title: 'Доставка' },
                    { code: 'menu', title: 'Электронное меню' }
                ],
                permitions: {
                    delivery: false,
                    full_menu: false,
                },
                mapZoom: 12,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                oldSite: state => state.entity
            }),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            siteLinkPreset() {
                return `${ process.env.VUE_APP_FRONT_URL }/`
            },
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const id = this.$route.params.id;
            await store.dispatch('siteTypes/fetch');
            if(id) {
                await store.dispatch('sites/get', {id});
                this.entity = {
                    ...this.entity,
                    ...this.oldSite
                };
                this.permitions = {
                    ...this.permitions,
                    ...this.oldSite.permitions
                };
                this.entity.coordinates = this.entity.coordinates || [];
            }
        },

        methods: {
            updateTitle(title) {
                this.entity.code = translit(title);
            },
            async setPicture(file) {
                this.entity.picture = await store.dispatch('files/upload', { file });
            },
            async setMenuFile(file) {
                this.entity.menu_file = await store.dispatch('files/upload', { file });
            },
            async removeMenuFile(file) {
                this.entity.menu_file = null;
            },
            async setLegalInformation(file) {
                this.entity.legal_information = await store.dispatch('files/upload', { file });
            },
            async removeLegalInformation(file) {
                this.entity.legal_information = null;
            },
            async save() {
                try {
                    this.entity.permitions = this.permitions;
                    store.commit('sites/SET_ENTITY', this.entity);
                    await store.dispatch('sites/save');
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('sites/delete', { id });
                    this.$router.push('/sites');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/sites/${ id }/detail`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>

<style lang="scss">
    .entity_edit {
        &__map {
            width: 100%;
            height: 400px;

            &__wrapper {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
</style>